<template>
  <div class="home_top">
    <div class="inner inner1">
      <div class="left_item">
        <h1><span>LendMax</span> </h1>
        <!-- <h1>Your trusted financial</h1> -->
        <!-- <h1>partner in Nigeria</h1> -->
        <h2>Your trusted lending platform in Nigeria. </h2>
        <div class="item_download" @click="downloadApp()">
          <img src="@/assets/images/loanImg/Google-play.png" alt="">
        </div>
      </div>
    </div>
    <div class="inner inner2">
      <h1>Why choose LendMax? </h1>
      <div class="content flex fw-w jc-sb">
        <div v-for="( item,index ) in inner_2item" :key="index" :style="{width:'31%'}" class="flex ai-c">
          <img :src="item.img">
          {{ item.txt }}
        </div>
      </div>
    </div>
    <div id="LoanProduct" class="inner inner3">
      <h1>How to apply for a loan <br>on LendMax? </h1>
      <div class="flex jc-sb content">
        <div class="flex fd-c jc-sb">
          <p>Download the LendMax app from the Google Play Store.
            <img src="@/assets/images/loanImg/one.png">
          </p>
          <p>Once your account is set up, enter the loan amount and the repayment period that suits your needs.
            <img src="@/assets/images/loanImg/three.png">
          </p>
          <p>Submit your application and wait for our
            quick approval process.
            <img src="@/assets/images/loanImg/five.png">
          </p>
        </div>
        <!-- <img src="~@/assets/images/loanImg/about_us2.png"> -->
        <h4 class="bottom_p">Please note that the loan approval and disbursal <br>
          process may vary depending on your individual<br>
          eligibility and the app’s internal policies. Make<br>
          sure to read and understand the terms and<br>
          conditions before applying for a loan.<br>
        </h4>
        <div class="flex fd-c jc-sb right">
          <p>
            <img src="@/assets/images/loanImg/two.png">
            Open the app and create an account by
            providing your personal details.
          </p>
          <p>
            <img src="@/assets/images/loanImg/four.png">
            Fill in the required information, including
            your employment status, monthly income,
            and any other necessary details.
          </p>
          <p>
            <img src="@/assets/images/loanImg/six.png">
            Once approved, the loan amount will be
            deposited directly into your bank account
            within hours.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      inner_2item: [
        { txt: 'Easy Application: Our loan application process is \n simple and hassle-free. Just download the app, \n create an account, and fill out the online application \n form. It only takes a few minutes!', img: require('@/assets/images/icon-4.png') },
        { txt: 'Fast Approval: Say goodbye to long waiting times. \n With LendMax, you can get approved for a loan \n within hours, even minutes!', img: require('@/assets/images/icon-5.png') },
        { txt: 'Flexible Loan Amounts: Whether you need \n a small loan or a larger amount, we have \n options for you.', img: require('@/assets/images/icon-6.png') },
        { txt: 'Customized Repayment Plans: We understand that \n everyone’s financial situation is unique. That’s why \n we offer flexible repayment plans tailored to your \n needs. You can choose to repay your loan in \n weekly, bi-weekly, or monthly installments.', img: require('@/assets/images/icon-7.png') },
        { txt: 'No Hidden Fees: At LendMax, we believe in \n transparency. There are no hidden fees or \n charges. The interest rates and repayment \n terms are clearly stated before you accept the loan.', img: require('@/assets/images/icon-8.png') },
        { txt: 'Secure and Confidential: Protecting your personal  \n and financial information is our top priority. We use  \n advanced encryption technology to ensure that your  \n data is safe and secure.', img: require('@/assets/images/icon-9.png') }
      ],
      inner_4items: [
        // 您的轮播内容数据
        { id: '1', name: 'Lando Wolf', txt: 'Descarga la App', txt2: 'Download and Install the App: Visit our website \n or your device’s app store to download and install \n LendMax on your mobile phone. ', img: require('@/assets/images/icon-1.png') },
        { id: '2', name: 'Alice Ura', txt: 'Registrarse y verificar', txt2: 'Sign Up: Open the app and sign up for an account \n using your mobile number. We will send you a \n verification code to ensure the security of your \n account.', img: require('@/assets/images/icon-2.png') },
        { id: '3', name: 'Marino Zavaleta', txt: 'Completar la solicitud', txt2: 'Apply for a Loan: Once you have successfully \n signed up, you can apply for a loan by selecting \n the loan amount and the repayment duration that \n suits your needs. Our loan application process is \n quick and straightforward, with minimal \n requirements.', img: require('@/assets/images/icon-3.png') }
      ]
    }
  },
  methods: {
    downloadApp() {
      window.open('https://play.google.com/store/apps/details?id=lendmax.money.credit')
    }
  }
}
</script>

<style lang="scss" scoped>
  .home_top{
    min-width: 1200px;
    background: #fff;
    .inner{
      width:1400px;
      // padding:50px calc((100% - 1100px) / 2) 50px;
      margin: 0 auto;
    }
    .bottom-20{
      margin-bottom: 20px;
    }
    .inner1{
      // background: linear-gradient(258deg, #43C5D6 0%, #4484EC 100%);
      background: url("~@/assets/images/loanImg/inner1_bg.png") no-repeat;
      background-size: auto 100%;
      background-position-x: center;
      height: 38rem;
      display: flex;
      align-items: center;
      color: black;
      padding: 0 190px;
      .left_item{
        float: left;
        width: 740px;
        h1{
          font-size: 55px;
          // color: white;
          font-weight: 700;
          margin-bottom: 40px;
          line-height: 70px;
        }
        h2{
          padding-right: 17.5px;
          font-size: 30px;
          line-height: 26px;
          // margin-bottom: 10px;
        }
        // span{
        //   color: #FF5833;
        // }
        .item_progress{
          width: 230px;
          p{
            width: 90px;
            text-align: center;
            line-height: 30px;
            border-radius: 5px;
            color: white;
            font-size: 12px;
            font-weight: bold;
            margin: 0 auto 10px;
            background: #4488EB;
          }
          img{
            width: 245px;
            height: 15px;
          }
        }
        .item_info{
          margin: 20px 0;
          display: flex;
          height: 100px;
          .info_img1{
            width: 15px;
          }
          .info_tips{
            display: flex;
            flex-direction: column;
            p{
              flex: 1;
              display: flex;
              box-sizing: border-box;
              // margin-left: 10px;
              font-size: 12px;
              color: #666666;
              align-items: center;
              img{
                margin-right: 10px;
              }
            }
          }
          .info_img2{
            width: 135px;
            height: 135px;
          }
        }
        .item_download{
          display: flex;
          margin-top: 80px;
          width: 200px;
          // background: black;
          color: white;
          border-radius: 5px;
          align-items: center;
          justify-content: center;
          img{
            width: 200px;
            height: 60px;
            margin-right: 5px;
          }
        }
      }
      .right_item{
        float: right;
        // margin-right: 50px;
        img{
          width: 550px;
        }
      }
    }
    .inner2{
      padding: 80px 0;
      h1{
        font-size: 40px;
        line-height: 100px;
        text-align: center;
        margin-bottom: 60px;
      }
      .content{
        padding: 0 5rem;
        div{
          padding: 50px 20px 40px;
          background: #E9EDF9;
          margin-bottom: 60px;
          text-align: center;
          font-size: 18px;
          line-height: 30px;
          border-radius: 15px;
          position: relative;
          img{
            position: absolute;
            width: 80px;
            top: -40px;
            left: calc(50% - 40px);
          }
        }
      }
    }
    .inner3{
      // background: linear-gradient(to bottom,#fff 0%,#fff 78%,#FE9A11 78% ,#F56732 100%);
      background: url("~@/assets/images/loanImg/inner2_bg.png") no-repeat;
      background-size: 100% 100%;
      text-align: center;
      padding: 40px 190px 0;
      position: relative;
      h1{
        font-size: 25px;
        line-height: 40px;
      }
      .bottom_p{
        width: 350px;
        font-size: 13px;
        line-height: 25px;
        position: absolute;
        bottom: 20px;
        left: calc(50% - 175px);
        font-weight: normal;
      }
      .content{
        // margin-top: 100px;
        div{
          width: 32%;
          text-align: left;
          padding: 0 0 40px 0;
          font-size: 16px;
          line-height: 25px;
        }
        p{
          display: flex;
          align-items: center;
          margin-bottom: 53px;
          img{
            width: 80px;
            height: 89px;
          }
        }
        .right{
          p{
            text-align: right;
          }
        }
      }
    }
  }
@media only screen and (max-width: 720px){
  .home_top{
    min-width: calc(100vw) !important;
    .inner{
      min-width: calc(100vw) !important;
      display: flex;
      flex-wrap: wrap;
      width: 100vw;
    }
    .inner1{
      height: 270px;
      position: relative;
      padding: 0 20px;
      h1{
        font-size: 18px !important;
        line-height: 25px !important;
        margin-bottom: 0 !important;
      }
      h2{
        font-size: 12px !important;
      }
      .left_item{
        margin: 25px 0;
        .item_download{
          margin-top: 20px;
          justify-content:start;
          img{
            width: 150px;
            height: 50px;
          }
        }
      }
      .right_item{
        width: calc(100vw);
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .inner2{
      padding: 0;
      h1{
        font-size: 25px;
        width: 100%;
        text-align: center;
        margin-bottom: 0;
      }
      .content{
        padding: 0;
        justify-content: center !important;
        div{
          width: 90% !important;
          padding: 40px 10px 20px;
          font-size: 14px;
          img{
            width: 60px;
            top: -30px;
            left: calc(50% - 30px);
          }
        }
      }
    }
    .inner3{
      padding: 0 15px;
      width: 100%;
      margin-top: 0px;
      background: #fff !important;
      h1{
        width: 100%;
        font-size: 25px;
        margin-bottom: 15px;
      }
      .content{
        div{
          width: auto;
        }
        p{
          margin-bottom: 20px;
          font-size: 12px;
          line-height: 18px;
          img{
            width: 30px;
            height: 33px;
          }
        }
        .bottom_p{
          display: none;
        }
      }
    }
  }
}
</style>
